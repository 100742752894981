 <template>
  <div class="p-grid p-fluid dashboard">
    <Toast/>
    <div class="p-col-12 p-lg-4">
      <div class="card summary">
        <span class="title">电箱总数</span>
        <span class="detail">
          今日新增：{{ totals.boxToday }} 昨日：{{ totals.boxYesterday }} 本月：{{
            totals.boxMonth
          }}
          上月：{{ totals.boxLastMonth }}
        </span>
        <router-link to='/installation'>
          <span class="count visitors">{{ totals.boxTotal }}</span>
        </router-link>
      </div>
    </div>
    <div class="p-col-12 p-lg-4">
      <div class="card summary">
        <span class="title">警报总数</span>
        <span class="detail">
          今日警报：{{ totals.faultToday }} 昨日：{{
            totals.faultYesterday
          }}
          本月：{{ totals.faultMonth }} 上月：{{ totals.faultLastMonth }}
        </span>
        <router-link to='/mapTest'>
          <span class="count purchases">{{ totals.faultTotal }}</span>
        </router-link>
      </div>
    </div>
    <div class="p-col-12 p-lg-4">
      <div class="card summary">
        <span class="title">电箱在线数量</span>
        <span class="detail">
          离线：{{ totals.boxTotal - totals.onlineTotal }}
        </span>
        <router-link to='/installation'>
          <span class="count revenue">{{ totals.onlineTotal }}</span>
        </router-link>
      </div>
    </div>
    <div class="p-col-12 p-lg-5">
      <div class="card">
        <h1 style="font-size: 16px">近期添加的电箱</h1>
        <DataTable
          :value="totals.boxList10"
          class="p-datatable-sm"
          :rows="10"
          :resizableColumns="true"
          columnResizeMode="fit"
        >
          <Column field="createTime" header="创建时间" headerStyle="width: 40%">
            <template #body="item">
              {{ fmtDate(item.data.createTime) }}
            </template>
          </Column>
          <Column
            field="electricCode"
            header="电箱码"
            headerStyle="width: 40%"
          ></Column>
          <Column
            field="userName"
            header="业主"
            headerStyle="width: 20%"
          ></Column>
        </DataTable>
      </div>
    </div>
    <div class="p-col-12 p-lg-7">
      <div class="card">
        <h1 style="font-size: 16px">近期发生的警报</h1>
        <DataTable
          :value="totals.faultList10"
          class="p-datatable-sm"
          :rows="10"
          :resizableColumns="true"
          columnResizeMode="fit"
        >
          <Column field="rtcTime" header="时间" headerStyle="width: 30%">
            <template #body="item">
              {{ fmtDate(item.data.rtcTime) }}
            </template>
          </Column>
          <Column
            field="electricCode"
            header="电箱码"
            headerStyle="width: 25%"
          ></Column>
          <Column
            field="lineCode"
            header="线路"
            headerStyle="width: 10%"
          ></Column>
          <Column field="lineStatus" header="状态" headerStyle="width: 20%">
            <template #body="item">
              {{ faultName(item.data.lineStatus) }}
            </template>
          </Column>
          <Column field="lineValue" header="数值" headerStyle="width: 15%"></Column>
        </DataTable>
      </div>
    </div>
    <div class="p-col-12 p-lg-5">
      <div class="card">
        <h1 style="font-size: 16px">近期上下线的电箱</h1>
        <DataTable
          :value="totals.onlineList10"
          class="p-datatable-sm"
          :rows="10"
          :resizableColumns="true"
          columnResizeMode="fit"
        >
          <Column field="date" header="时间" headerStyle="width: 40%">
            <template #body="item">
              {{ fmtDate(item.data.date) }}
            </template>
          </Column>
          <Column
            field="electricCode"
            header="电箱码"
            headerStyle="width: 40%"
          ></Column>
          <Column field="online" header="状态" headerStyle="width: 20%">
            <template #body="item">
              {{ item.data.online ? "在线" : "离线" }}
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
    <!-- <div class="p-col-12 p-md-6 p-xl-3">
      <div class="highlight-box">
        <div class="initials" style="background-color: #007be5; color: #00448f">
          <span>TV</span>
        </div>
        <div class="highlight-details">
          <i class="pi pi-search"></i>
          <span>Total Queries</span>
          <span class="count">523</span>
        </div>
      </div>
    </div>
    <div class="p-col-12 p-md-6 p-xl-3">
      <div class="highlight-box">
        <div class="initials" style="background-color: #ef6262; color: #a83d3b">
          <span>TI</span>
        </div>
        <div class="highlight-details">
          <i class="pi pi-question-circle"></i>
          <span>Total Issues</span>
          <span class="count">81</span>
        </div>
      </div>
    </div>
    <div class="p-col-12 p-md-6 p-xl-3">
      <div class="highlight-box">
        <div class="initials" style="background-color: #20d077; color: #038d4a">
          <span>OI</span>
        </div>
        <div class="highlight-details">
          <i class="pi pi-filter"></i>
          <span>Open Issues</span>
          <span class="count">21</span>
        </div>
      </div>
    </div>
    <div class="p-col-12 p-md-6 p-xl-3">
      <div class="highlight-box">
        <div class="initials" style="background-color: #f9c851; color: #b58c2b">
          <span>CI</span>
        </div>
        <div class="highlight-details">
          <i class="pi pi-check"></i>
          <span>Closed Issues</span>
          <span class="count">60</span>
        </div>
      </div>
    </div> -->

    <!-- <div class="p-col-12 p-md-6 p-lg-4">
      <Panel header="Tasks" style="height: 100%">
        <ul class="task-list">
          <li>
            <Checkbox name="task" value="reports" v-model="tasksCheckbox" />
            <span class="task-name">Sales Reports</span>
            <i class="pi pi-chart-bar" />
          </li>
          <li>
            <Checkbox name="task" value="invoices" v-model="tasksCheckbox" />
            <span class="task-name">Pay Invoices</span>
            <i class="pi pi-dollar" />
          </li>
          <li>
            <Checkbox name="task" value="dinner" v-model="tasksCheckbox" />
            <span class="task-name">Dinner with Tony</span>
            <i class="pi pi-user" />
          </li>
          <li>
            <Checkbox name="task" value="meeting" v-model="tasksCheckbox" />
            <span class="task-name">Client Meeting</span>
            <i class="pi pi-users" />
          </li>
          <li>
            <Checkbox name="task" value="theme" v-model="tasksCheckbox" />
            <span class="task-name">New Theme</span>
            <i class="pi pi-globe" />
          </li>
          <li>
            <Checkbox name="task" value="flight" v-model="tasksCheckbox" />
            <span class="task-name">Flight Ticket</span>
            <i class="pi pi-briefcase" />
          </li>
        </ul>
      </Panel>
    </div> -->

    <!-- <div class="p-col-12 p-md-6 p-lg-4 p-fluid contact-form">
      <Panel header="Contact Us">
        <div class="p-grid">
          <div class="p-col-12">
            <Dropdown
              v-model="dropdownCity"
              :options="dropdownCities"
              optionLabel="name"
              placeholder="Select a City"
            />
          </div>
          <div class="p-col-12">
            <InputText type="text" placeholder="Name" />
          </div>
          <div class="p-col-12">
            <InputText type="text" placeholder="Age" />
          </div>
          <div class="p-col-12">
            <InputText type="text" placeholder="Message" />
          </div>
          <div class="p-col-12">
            <Button type="button" label="Send" icon="pi pi-envelope" />
          </div>
        </div>
      </Panel>
    </div> -->

    <!-- <div class="p-col-12 p-lg-4 contacts">
      <Panel header="Contacts">
        <ul>
          <li>
            <button class="p-link">
              <img
                src="assets/layout/images/avatar_1.png"
                width="35"
                alt="avatar1"
              />
              <span class="name">Claire Williams</span>
              <span class="email">clare@primevue.com</span>
            </button>
          </li>
          <li>
            <button class="p-link">
              <img
                src="assets/layout/images/avatar_2.png"
                width="35"
                alt="avatar2"
              />
              <span class="name">Jason Dourne</span>
              <span class="email">jason@primevue.com</span>
            </button>
          </li>
          <li>
            <button class="p-link">
              <img
                src="assets/layout/images/avatar_3.png"
                width="35"
                alt="avatar3"
              />
              <span class="name">Jane Davidson</span>
              <span class="email">jane@primevue.com</span>
            </button>
          </li>
          <li>
            <button class="p-link">
              <img
                src="assets/layout/images/avatar_4.png"
                width="35"
                alt="avatar4"
              />
              <span class="name">Tony Corleone</span>
              <span class="email">tony@primevue.com</span>
            </button>
          </li>
        </ul>
      </Panel>
    </div> -->

    <!-- <div class="p-col-12 p-lg-6">
      <div class="card">
        <Chart type="line" :data="lineData" />
      </div>
    </div> -->
  </div>
</template>

<script>
import ProductService from "../service/ProductService";
import EventService from "../service/EventService";
import axios from "axios";
import { getCurrentInstance } from "@vue/runtime-core";

export default {
  data() {
    return {
      totals: {
        boxTotal: 0,
        boxList10: [],
        boxToday: 0,
        boxYesterday: 0,
        boxMonth: 0,
        boxLastMonth: 0,
        faultTotal: 0,
        faultList10: [],
        faultToday: 0,
        faultYesterday: 0,
        faultMonth: 0,
        faultLastMonth: 0,
        onlineTotal: 0,
        onlineList10: [],
      },
      tasksCheckbox: [],
      dropdownCities: [
        { name: "New York", code: "NY" },
        { name: "Rome", code: "RM" },
        { name: "London", code: "LDN" },
        { name: "Istanbul", code: "IST" },
        { name: "Paris", code: "PRS" },
      ],
      dropdownCity: null,
      options: {
        defaultDate: "2019-01-01",
        header: {
          left: "prev,next",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay",
        },
        editable: true,
      },
      events: null,
      products: null,
      selectedProducts: null,
      lineData: {
        labels: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
        ],
        datasets: [
          {
            label: "First Dataset",
            data: [65, 59, 80, 81, 56, 55, 40],
            fill: false,
            backgroundColor: "#2f4860",
            borderColor: "#2f4860",
          },
          {
            label: "Second Dataset",
            data: [28, 48, 40, 19, 86, 27, 90],
            fill: false,
            backgroundColor: "#00bb7e",
            borderColor: "#00bb7e",
          },
        ],
      },
    };
  },
  productService: null,
  eventService: null,
  created() {
    this.productService = new ProductService();
    this.eventService = new EventService();
  },
  mounted() {
    this.productService
      .getProductsSmall()
      .then((data) => (this.products = data));
    this.eventService.getEvents().then((data) => (this.events = data));

    let afId = this.$route.query["af_id"];
    if (afId) {
      let today = new Date();
      let expire = new Date();
      expire.setTime(today.getTime() + 3600000 * 24 * 7);
      document.cookie =
        "primeaffiliateid=" +
        afId +
        ";expires=" +
        expire.toUTCString() +
        ";path=/; domain:primefaces.org";
    }
    this.fetch();
  },
  methods: {
    formatCurrency(value) {
      return value.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },
    err(msg, title, life) {
      this.$toast.add({
        severity: "error",
        summary: title,
        detail: msg,
        life: life || 3000,
      });
    },
    fetch() {
      axios
        .post("/houseKeeperFault/user/getCompanyTotals")
        .then(({ data }) => {
          if (data.code === 0) {
            this.totals = data.data;
          } else{
            if(data.msg === '当前账号在其他地点登录，请重新登录' && data.code === -3){
              this.$router.push('/login');
            }
            this.err(data.msg, "查询失败", 5000);
          }
        })
        .catch((err) => {
          console.error(err);
          this.err("系统错误，请稍候重试");
        });
    },
    fmtDate(date, fmt) {
      const { proxy } = getCurrentInstance();
      return proxy.$fmtDate(date, fmt);
    },
    faultName(status) {
      const { proxy } = getCurrentInstance();
      return proxy.$faultNames[status];
    },
  },
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 960px) {
  ::v-deep(.p-datatable) {
    &.p-datatable-customers {
      .p-datatable-thead > tr > th,
      .p-datatable-tfoot > tr > td {
        display: none !important;
      }

      .p-datatable-tbody > tr {
        border-bottom: 1px solid #dee2e6;
        > td {
          text-align: left;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 0 none !important;
          width: 100% !important;
          float: left;
          clear: left;
          border: 0 none;

          .p-column-title {
            padding: 0.4rem;
            min-width: 30%;
            display: inline-block;
            margin: -0.4rem 1rem -0.4rem -0.4rem;
            font-weight: bold;
          }

          .p-progressbar {
            margin-top: 0.5rem;
          }
        }
      }
    }
  }
}
</style>
